import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { authConfig } from './auth-config';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

const providers: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeOAuthService,
    multi: true,
    deps: [OAuthService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeUser,
    multi: true,
    deps: [UserService],
  },
];
if (StorageService.hasStorage) {
  providers.push({ provide: OAuthStorage, useFactory: storageFactory });
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true,
      },
    }),
  ],
  providers,
})
export class AuthModule {}

function initializeOAuthService(oauthService: OAuthService) {
  return async () => {
    if (!StorageService.isSSR) {
      oauthService.configure(authConfig);
    }
  };
}
function initializeUser(userService: UserService) {
  return async () => {
    if (!StorageService.isSSR) {
      await userService.currentUser$.pipe(take(1)).toPromise();
    }
  };
}

// We need a factory, since localStorage is not available during AOT build time.
function storageFactory(): OAuthStorage {
  return StorageService.oauthStorage;
}
