import { OAuthStorage } from 'angular-oauth2-oidc';

const storage = typeof Storage !== 'undefined' ? localStorage : null;
class StorageImplementation {
  get oauthStorage(): OAuthStorage {
    return storage as OAuthStorage;
  }
  private get internalStorage() {
    return storage;
  }
  get hasStorage() {
    return this.internalStorage !== null;
  }
  get isSSR() {
    return this.internalStorage === null;
  }
  getItem(key: string) {
    return this.internalStorage?.getItem(key);
  }
  setItem(key: string, value: string) {
    this.internalStorage?.setItem(key, value);
  }
  removeItem(key: string) {
    return this.internalStorage?.removeItem(key);
  }
}
export const StorageService = new StorageImplementation();
