export const environment = {
  production: true,
  apiUrl: 'https://medicalchambers.api.preview-site.website',
  publicUrl: 'https://admin-medicalchambers.preview-site.website',
  clientSettings: {
    authority: 'https://medicalchambers.api.preview-site.website',
    client_id: 'admin',
    scope: 'openid profile email admin role',
  },
};
