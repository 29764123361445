import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app-routes';
import { UserService } from 'src/app/auth/user.service';

import { NavItem } from './nav-item';

@Component({
  selector: 'mca-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.scss'],
})
export class NavItemsComponent implements OnInit {
  @Output()
  linkClicked = new EventEmitter<any>();

  links$!: Observable<NavItem[]>;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.links$ = this.userService.currentUser$.pipe(
      map((user) => {
        return AppRoutes.filter((x) => x.showInNav)
          .filter((x) =>
            UserService.canAccess(user, x.data?.additionalRoles ?? [])
          )
          .map(
            (x) =>
              ({
                name: x.displayName,
                link: `/${x.path}`,
                icon: x.icon,
              } as NavItem)
          );
      })
    );
  }

  clicked() {
    this.linkClicked.next();
  }

  isAuthenticated() {
    return this.userService.currentUser$.pipe(map(UserService.isAdmin));
  }
}
