<mca-admin-page title="Home">
  <ng-container page-data>
    <ng-container *ngIf="user$ | async as user; else loginTemplate">
      <div class="alert alert-info" *ngIf="user">Logged in as {{ user }}</div>
    </ng-container>
    <ng-template #loginTemplate>
      <button (click)="login()" class="btn btn-primary">Login</button>
    </ng-template>
  </ng-container>
</mca-admin-page>
