import { UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';

class AppStateData {
  constructor() {
    this.initialize();
  }
  get isUserInitialized() {
    return this.user$?.value !== this.initialUserInfo;
  }
  private initialUserInfo = {} as UserInfo;
  public user$!: BehaviorSubject<UserInfo | null>;

  private initialize() {
    this.user$ = new BehaviorSubject<UserInfo | null>(this.initialUserInfo);
  }
}

export const AppState = new AppStateData();
