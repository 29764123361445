import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { StorageService } from './storage.service';
import { UserService } from './user.service';

const redirectKey = 'login.redirectKey';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private userService: UserService,
    private router: Router,
    private oauthService: OAuthService
  ) {}
  private static setRedirectUrl(redirectUrl?: string) {
    if (StorageService.hasStorage) {
      if (redirectUrl) StorageService.setItem(redirectKey, redirectUrl);
    }
  }
  private static getRedirectUrl() {
    if (StorageService.hasStorage) {
      const url = StorageService.getItem(redirectKey);
      StorageService.removeItem(redirectKey);
      return url;
    }
    return null;
  }
  async registerAutomaticRefresh() {
    this.oauthService.events.subscribe((ev) => {
      // console.log({ ev });
    });
    this.oauthService.setupAutomaticSilentRefresh();
  }

  async login(redirectUrl?: string) {
    LoginService.setRedirectUrl(redirectUrl || '/');
    return await this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  insufficientPermissions(url: string) {
    this.router.navigateByUrl(
      `/auth/insufficient-permissions?url=${encodeURIComponent(url)}`
    );
  }

  async logout() {
    await this.oauthService
      .loadDiscoveryDocument()
      .then((_) => this.oauthService.revokeTokenAndLogout());
  }

  async completeAuthentication(): Promise<boolean> {
    try {
      const result = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (!(result && this.oauthService.hasValidAccessToken())) {
        return false;
      }

      const user: any = await this.oauthService.loadUserProfile();
      this.userService.setUser(user?.info ?? null);

      const url = LoginService.getRedirectUrl();
      if (url) {
        this.router.navigateByUrl(url);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}
