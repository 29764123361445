import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { AdminModalComponent } from './admin-modal/admin-modal.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { DataInterceptor } from './interceptors/data-interceptor';
import { AngularDateHttpInterceptor } from './interceptors/date-interceptor';
import { LoadingModule } from './loading/loading.module';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { BreakUpStringPipe } from './pipes/break-up-string.pipe';
import { NewlineToBreaksPipe } from './pipes/newline-to-breaks.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { YoutubeEmbedComponent } from './youtube-embed/youtube-embed.component';

@NgModule({
  declarations: [
    AdminPageComponent,
    BreadcrumbComponent,
    NavItemsComponent,
    BreakUpStringPipe,
    AdminModalComponent,
    YoutubeEmbedComponent,
    SafePipe,
    NewlineToBreaksPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LoadingModule,
    HttpClientModule,
    CKEditorModule,
  ],
  exports: [
    AdminPageComponent,
    BreadcrumbComponent,
    NavItemsComponent,
    BreakUpStringPipe,
    AdminModalComponent,
    YoutubeEmbedComponent,
    SafePipe,
    NewlineToBreaksPipe,
    CKEditorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DataInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    {
      provide: NgbModalConfig,
      useValue: { backdrop: 'static' } as NgbModalConfig,
    },
  ],
})
export class SharedModule {}
