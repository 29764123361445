import { Route } from '@angular/router';

import { IsLoggedInGuard } from './auth/is-logged-in.guard';
import { LoginComponent } from './login/login.component';

interface AdminRoute extends Route {
  showInNav: boolean;
  displayName: string;
  icon?: string;
}

export const AppRoutes: AdminRoute[] = [
  {
    path: '',
    component: LoginComponent,
    showInNav: false,
    displayName: 'Home',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-ui/auth-ui.module').then((x) => x.AuthUiModule),
    showInNav: false,
    displayName: 'Auth',
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((x) => x.PagesModule),
    canActivate: [IsLoggedInGuard],
    data: {
      additionalRoles: ['CMS User'],
    },
    showInNav: true,
    displayName: 'Pages',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'page-enhancements',
    loadChildren: () =>
      import('./page-enhancements/page-enhancements.module').then(
        (x) => x.PageEnhancementsModule
      ),
    data: {
      additionalRoles: ['CMS User'],
    },
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Page Enhancements',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./media/media.module').then((x) => x.MediaModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Media',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'authors',
    loadChildren: () =>
      import('./authors/authors.module').then((x) => x.AuthorsModule),
    canActivate: [IsLoggedInGuard],
    data: {
      additionalRoles: ['CMS User'],
    },
    showInNav: true,
    displayName: 'Authors',
    icon: 'fas fa-user-edit',
  },
  {
    path: 'articles',
    loadChildren: () =>
      import('./articles/articles.module').then((x) => x.ArticlesModule),
    canActivate: [IsLoggedInGuard],
    data: {
      additionalRoles: ['CMS User'],
    },
    showInNav: true,
    displayName: 'Articles',
    icon: 'far fa-newspaper',
  },
  {
    path: 'enquiries',
    loadChildren: () =>
      import('./enquiries/enquiries.module').then((x) => x.EnquiriesModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Enquiries',
    icon: 'far fa-comment',
  },
  {
    path: 'layout-templates',
    loadChildren: () =>
      import('./layout-templates/layout-templates.module').then(
        (x) => x.LayoutTemplatesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Layout Templates',
    icon: 'far fa-comment',
  },
  {
    path: 'email-templates',
    loadChildren: () =>
      import('./email-templates/email-templates.module').then(
        (x) => x.EmailTemplatesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Email Templates',
    icon: 'far fa-comment',
  },
  {
    path: 'email-messages',
    loadChildren: () =>
      import('./email-messages/email-messages.module').then(
        (x) => x.EmailMessagesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Email messages',
    icon: 'far fa-comment',
  },
  {
    path: 'testimonials',
    loadChildren: () =>
      import('./testimonials/testimonials.module').then(
        (x) => x.TestimonialsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Testimonials',
    icon: 'fas fa-award',
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faqs/faqs.module').then((x) => x.FaqsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'FAQs',
    icon: 'fas fa-question',
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((x) => x.UsersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Users',
    icon: 'fas fa-user',
  },
];
