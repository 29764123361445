import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/auth/login.service';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'mca-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})
export class AdminPageComponent implements OnInit {
  constructor(
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: any,
    private loginService: LoginService,
    private userService: UserService
  ) {}

  navClosed = true;
  @Input()
  title: string | undefined;

  @Input()
  loading = false;

  @Input()
  acting = false;

  ngOnInit(): void {}

  toggleSidebar() {
    this.navClosed = !this.navClosed;
    if (!this.navClosed) {
      this._document.getElementById('sidebar').scrollTop = 0;
    }
  }

  closeMenu() {
    this.navClosed = true;
  }
  get isAuthenticated() {
    return this.userService.currentUser$?.pipe(
      map(UserService.isAuthenticated)
    );
  }

  signOut() {
    this.loginService.logout();
  }
}
