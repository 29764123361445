import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/auth/login.service';
import { UserService } from 'src/app/auth/user.service';
import { BreadcrumbItem } from 'src/app/shared/breadcrumb/breadcrumb-item';

@Component({
  selector: 'mca-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public breadcrumbs: BreadcrumbItem[] = [
    {
      name: 'Home',
      parts: ['/'],
    },
  ];

  constructor(
    private userService: UserService,
    private loginService: LoginService
  ) {}

  ngOnInit() {}

  login() {
    this.loginService.login();
  }

  get authed$() {
    return this.userService.currentUser$?.pipe(
      map(UserService.isAuthenticated)
    );
  }

  get user$() {
    return this.userService.currentUser$?.pipe(map((user) => user?.name));
  }
  get roles$() {
    return this.userService.currentUser$?.pipe(
      map((user) => user?.roles || [])
    );
  }
  // register() {
  //   const modal = this.modal.open(RegisterComponent);
  //   modal.result.then(
  //     result => (this.registrationSuccess = result),
  //     () => {}
  //   );
  // }
}
